import React from 'react';

const Camps = () => {
    return(
        <>
            <header className="mastheadCamps">
                <div className="container px-4 px-lg-5 h-100">
                    <div className="row gx-4 gx-lg-5 h-100 align-items-center justify-content-center text-center">
                    <div className="col-lg-8 align-self-end">
                        <h1 className="text-white font-weight-bold">Seasonal Camps</h1>
                        <hr className="divider" />
                    </div>
                    <div className="col-lg-8 align-self-baseline">
                        <p className="text-white-75 mb-5">Keep an eye on our calander for camp dates.</p>
                    </div>
                    </div>
                </div>
            </header>
            <div className="container p-5">
                <div className="row align-items-center" title="Camps Info">    
                            <section className="page-section bg-primary" >
                                <div className="container px-4 px-lg-5">
                                    <div className="row gx-4 gx-lg-5 justify-content-center">
                                    <div className="col-lg-8 text-center">
                                        <h2 className="text-white mt-0">Camp Activities</h2>
                                        <hr className="divider divider-light" />
                                        <ul className="text-white-75 mb-4 list-unstyled">
                                            <li>Horse back riding</li>
                                            <li>Daily horse care</li>
                                            <li>Horsemanship</li>
                                            <li>Teambuilding skills</li>
                                            <li>Horsey related arts, crafts and games!</li>
                                        </ul>
                                    </div>
                                    </div>
                                </div>
                            </section>
                            <img className="img-fluid mx-auto" src="assets/img/portfolio/thumbnails/kidsHorses.jpg" alt="..." />
                </div>
                <div className="row align-items-center"  title="Camps Info">
                        
                            <section className="page-section bg-primary">
                                <div className="container px-4 px-lg-5">
                                <div className="row gx-4 gx-lg-5 justify-content-center">
                                    <div className="col-lg-8 text-center">
                                        <h2 className="text-white mt-0">Camp Dates and Info</h2>
                                        <hr className="divider divider-light" />
                                        <ul className="text-white-75 mb-4 list-unstyled">
                                            <li>Summer Camp</li>
                                            <li> Dates: July 8th-11th & July 22nd-25th</li>
                                            <li>Camp Hours: 10am - 2pm</li>
                                            <li>$350/Week</li>
                                            <li>Ages 5 and up</li>
                                            <li>Rain or Shine!</li>
                                        </ul>
                                    </div>
                                    </div>
                                </div>
                            </section>
                            <img className="img-fluid mx-auto" src="assets/img/portfolio/fullsize/camp2.jpg" alt="..." />
                </div>
            </div>
        </>
    )
};

export default Camps;