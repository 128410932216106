import React from 'react';

const Calendar = () => {
    return(
        <section className="page-section bg-primary" id="calendar">
        <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                <iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23616161&ctz=America%2FNew_York&showPrint=0&showTabs=0&showTitle=0&src=ZnJmYXJtY29ueWVyc0BnbWFpbC5jb20&src=ZW4udXNhI2hvbGlkYXlAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&color=%23039BE5&color=%230B8043" title="FRFarmCalendar" style={{border: 'none 1px #777'}} width={800} height={600} />

                </div>
            </div>
        </section>
    )
};

export default Calendar;