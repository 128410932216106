
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import './App.css';
import{
  Nav,
  Masthead,
  About,
  Services,
  Contact,
  Footer,
  Calendar,
  Trainers,
  CalendarPage,
  FacSer,
  Camps,
  ScrollToTop,
  CampContactForm,
}from "./Components";

function App() {

  
  return (
    <Router className="App">
        <Nav/>
        
        <ScrollToTop>
          <Routes>
            <Route path="/" element={
              <>
                <Masthead/>
                <About/>
                <Services/>
                <Calendar/>
                <Contact/>
              </>
            }/>
            <Route path="/home" element={
              <>
                <Masthead/>
                <About/>
                <Services/>
                <Calendar/>
                <Contact/>
              </>
            }/>
            <Route path="/calendar" element={<CalendarPage/>}/>
            <Route path="/trainers" element={<Trainers/>}/>
            <Route path="Facility" element={
                <>
                  <FacSer />
                  <Contact/>
                </>
              }/>
            <Route path="Camps" element={
              <>
                <Camps />
                <CampContactForm />
              </>  
              }/>
              <Route path="Contact" element={
                <>
                  <About />
                  <Contact />
                </>
                } />
          </Routes>
        </ScrollToTop>

        <Footer/>
    </Router>
  );
}

export default App;
