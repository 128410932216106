import React from 'react';

const FacSer = () => {
    return(
        <>
            <header className="mastheadFac">
                <div className="container px-4 px-lg-5 h-100">
                    <div className="row gx-4 gx-lg-5 h-100 align-items-center justify-content-center text-center">
                    <div className="col-lg-8 align-self-end">
                        <h1 className="text-white font-weight-bold">Facilities and Services</h1>
                        {/* <hr className="divider" /> */}
                    </div>
                    <div className="col-lg-8 align-self-baseline">
                        <p className="text-white-75 mb-5"></p>
                    </div>
                    </div>
                </div>
            </header>
            <div className="container p-5">
                <div className="row align-items-center" title="Services">
                            <section className="page-section bg-primary" >
                                <div className="container px-4 px-lg-5">
                                    <div className="row gx-4 gx-lg-5 justify-content-center">
                                    <div className="col-lg-8 text-center">
                                        <h2 className="text-white mt-0">Barn & Facilities</h2>
                                        <hr className="divider divider-light" />
                                        <h3 className="text-white mt-0">Barn Details</h3>
                                        <ul className="text-white-75 mb-4 list-unstyled">
                                            <li>Top barn with five inside stalls and three outside facing stalls</li>
                                            <li>Bottom Barn with four stalls all equipt with attached paddocks</li>
                                            <li>Automatic Fly Spray System</li>
                                            <li>Hot and Cold wash racks</li>
                                            <li>Individual Tack Lockers for Boarders</li>
                                        </ul>
                                        <h3 className="text-white mt-0">Facilities</h3>
                                        <ul className="text-white-75 mb-4 list-unstyled">
                                            <li>Large lighted arena with full set of jumps</li>
                                            <li>Round Pen</li>
                                            <li>Six Beautiful Pastures</li>
                                        </ul>
                                    </div>
                                    </div>
                                </div>
                            </section>
                            <img className="img-fluid mx-auto" id="summerfestPic" src="assets/img/portfolio/fullsize/facilityPic.jpg" alt="..." />
                </div>
                <div className="row align-items-center" title="Services">
                            <section className="page-section bg-primary" >
                                <div className="container px-4 px-lg-5">
                                    <div className="row gx-4 gx-lg-5 justify-content-center">
                                    <div className="col-lg-8 text-center">
                                        <h2 className="text-white mt-0">2024 Boarding Packages</h2>
                                        <hr className="divider divider-light" />
                                        <h3 className="text-white mt-0">Full Board -$650</h3>
                                        <ul className="text-white-75 mb-4 list-unstyled">
                                            <li>Grain 2x per day, one flake of bermuda and one flake of alfalfa hay while stalled</li>
                                            <li>Daily/Nightly turnout, stall cleaning</li>
                                            <li>Daily health check, supplements as provided by owner, lunch hay as provided by owner</li>
                                            <li>Heavy blanket when below freezing and rainy. Extra blanketing is $50 per month additional</li>
                                        </ul>
                                        <h3 className="text-white mt-0">Pasture Board - $500</h3>
                                        <ul className="text-white-75 mb-4 list-unstyled">
                                            <li>Grain 2x per day and fresh water daily</li>
                                            <li>Daily health check</li>
                                            <li>Supplements provided by owner</li>
                                            <li>Hay during winter</li>
                                            <li>Heavy blanket when below freezing and rainy. Extra blanketing is $50 per month additional.</li>
                                        </ul>
                                        <p className="text-white fst-italic mt-0 ">Board is to be paid directly to Tim Hix by cash or check only. Any board paid after the 5th of the month will accrue a $25 late fee per day board is considered late.</p>
                                        <p className="text-white fst-italic mt-0 ">Any A La Carte services are to be considered with Eden and payment made at the time service is rendered.</p>
                                    </div>
                                    </div>
                                </div>
                            </section>
                            <img className="img-fluid mx-auto" id="summerfestPic" src="assets/img/portfolio/fullsize/IMG_7628.jpg" alt="..." />
                </div>
                <div className="row align-items-center" title="Facilities">
                            <section className="page-section bg-primary">
                                <div className="container px-4 px-lg-5">
                                    <div className="row gx-4 gx-lg-5 justify-content-center">
                                    <div className="col-lg-8 text-center">
                                        <h2 className="text-white mt-0">Lesson and Training Packages</h2>
                                        <hr className="divider divider-light" />
                                        <h3 className="text-white mt-0">Lesson Packages</h3>
                                        <ul className="text-white-75 mb-4 list-unstyled">
                                            <li>$1200/two lessons a week ($50 per lesson) - 3 months commitment - can pay in two installments</li>
                                            <li>$780/ one lesson a wekk ($65 per lesson) - 3 month commitment - can pay in two installments</li>
                                            <li>$340/one lesson a week ($85 per lesson) - 1 month commitment</li>
                                            <li>$100 per lesson if paid at time of service (non-owning/full leasing students)</li>
                                            <li>$75 per lesson if paid at time of service (owners and full leasers)</li>
                                        </ul>
                                        <p className="text-white fst-italic">Lesson times vary based off temperature and horse/rider goals but should last between 45-60 minutes otherwise.</p>
                                        <p className="text-white fst-italic">30-minute private lesson available upon request</p>
                                        <hr className="divider divider-light" />
                                        <h3 className="text-white mt-0">Training Packages</h3>
                                        <ul className="text-white-75 mb-4 list-unstyled">
                                            <li>3 rides per week + 1 massage per month - $550/month</li>
                                            <li>4 rides per week + 1 massage per month - $750/month</li>
                                            <li>6 rides per week + 2 massages per month - $1000/month</li>
                                        </ul>
                                        <p className="text-white fst-italic mt-0">All packages can be interchangeable with lessons</p>
                                        <p className="text-white fw-bold fst-italic">Please note, training packages do not include month board</p>
                                        <hr className="divider divider-light" />
                                        <h3 className="text-white mt-0">A La Carte Rides</h3>
                                        <ul className="text-white-75 mb-4 list-unstyled">
                                            <li>Training ride - $75 ($15 discount if owner preps and cools down).</li>
                                            <li>Lunging/Ground Work Session - $60 ($15 discount if owner preps and cools down).</li>
                                        </ul>
                                        <hr className="divider divider-light" />
                                        <h3 className="text-white mt-0">Cancellation and Reschedule Policies</h3>
                                        <ol className="text-white-75 mb-4 list-group list-group-numbered">
                                            <li className="list-group-item">There are no refunds except for severe illness or death.</li>
                                            <li className="list-group-item">Students will be removed from the schedule until invoices are paid in full.</li>
                                            <li className="list-group-item">Lessons do not roll over into the next month.</li>
                                            <li className="list-group-item">Riders must arrive 30 minutes prior to their lesson start time to ensure adequate time to retrieve, groom and tack up horse.</li>
                                            <li className="list-group-item">Make up lessons due to rider cancellations will only be held at trainer's discretion if you do not own or lease a horse, otherwise the lesson is forfeited and charged in full.</li>
                                            <li className="list-group-item">If a lesson is rescheduled due to weather or trainer cancellation, there is a two-week window to allow a make up for rescheduled lessons.</li>
                                            <li className="list-group-item">If rider enters the ring more than 15 minutes late, the lesson will be forfeited, and rider will be charged in full.</li>
                                            <li className="list-group-item">Any equipment broken while in the students care must be replaced.</li>
                                        </ol>
                                    </div>
                                    </div>
                                </div>
                            </section>
                            <img className="img-fluid mx-auto" src="assets/img/portfolio/thumbnails/IMG_8945.jpg" alt="..." />
                </div>
            </div>

        </>
    )
};

export default FacSer;