import React from 'react';
import { NavLink } from 'react-router-dom';

const About = () => {
    return(
   <section className="page-section bg-primary" id="about">
        <div className="container px-4 px-lg-5">
            <div className="row gx-4 gx-lg-5 justify-content-center">
            <div className="col-lg-8 text-center">
                <h2 className="text-white mt-0">Welcome Home</h2>
                <hr className="divider divider-light" />
                <p className="text-white-75 mb-4">Located in beautiful Monroe, Georgia. Fire Ring Farm features full care training and lesson programs tailored to you and your horse.</p>
                {/* Comment below is original if needed to be changed back */}
                {/* <a className="btn btn-light btn-xl" href="#services">Get Riding!</a> */}
                <NavLink className="btn btn-light btn-xl" to="/Facility" >Get Riding!</NavLink>
            </div>
            </div>
        </div>
    </section>

    )
}

export default About;