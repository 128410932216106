import React from 'react';

const Trainers = () => {
    return(
        <>
            <header className="mastheadTrainers">
                <div className="container px-4 px-lg-5 h-100">
                    <div className="row gx-4 gx-lg-5 h-100 align-items-center justify-content-center text-center">
                    <div className="col-lg-8 align-self-end">
                        <h1 className="shadow text-white font-weight-bold">Meet our trainers at Fire Ring Farm!</h1>
                        {/* <hr className="divider" /> */}
                    </div>
                    <div className="col-lg-8 align-self-baseline">
                        <p className="text-white-75 mb-5"></p>
                    </div>
                    </div>
                </div>
            </header>
            <div className="containerTrainer p-5">
                <div className="row align-items-center" title="Eden Miller">
                            <section className="page-section bg-primary" >
                                <div className="container px-4 px-lg-5">
                                    <div className="row gx-4 gx-lg-5 justify-content-center">
                                    <div className="col-lg-8 text-center">
                                        <h1 className="text-white mt-0">Head Trainer</h1>
                                        <hr className="divider divider-light" />
                                        <h2 className="text-white mt-0">Eden Miller</h2>
                                        <hr className="divider divider-light" />
                                        <p className="text-white-75 mb-4">Eden discovered her love for horses at the young age of six. She began her riding career at a beautiful little farm called River Song Manor where some of her fondest memories come to mind of long days spent at the farm soaking in as much time as she could learning and discovering her passion for horses. Eden got her first horse, Hootie, at 10 years old and the two of them had an incredible bond that would last 26 years until Hootie passed at the age of 36. Eden gives many thanks and gratitude to her girl, Hootie, who taught her how to be brave and follow her dreams. While Hootie and Eden had an extensive show career competing from hunters and equitation to high level jumpers, Eden was given the opportunity at a young age to show incredible horses under some of Georgia's top trainers winning many year-end championships titles in her junior years. While riding horses will forever be one of Edens favorite things in life, she also enjoys sharing her knowledge and finds great joy in seeing others discover their love and passion for horses just as she did. </p>
                                    </div>
                                    </div>
                                </div>
                            </section>
                            <img className="img-fluid mx-auto" src="assets/img/portfolio/thumbnails/EdenTrainer.jpg" alt="..." />
                </div>
                <div className="row align-items-center" title="Victoria Blake">
                        <section className="page-section bg-primary">
                                <div className="container gx-4 gx-lg-5">
                                    <div className="row gx-4 gx-lg-5 justify-content-center">
                                    <div className="col-lg-8 text-center">
                                    <h1 className="text-white mt-0">Assistant Trainer</h1>
                                        <hr className="divider divider-light" />
                                        <h2 className="text-white mt-0">Maya Sampson</h2>
                                        <hr className="divider divider-light" />
                                        <p className="text-white-75 mb-4">Maya joined the barn family recently as assistant trainer. She enjoys teaching lessons for all ages and experience levels. When she's not teaching or running horse camp, she spends a lot of time with her sweet horse, Theo.</p>
                                    </div>
                                    </div>
                                </div>
                            </section>
                            <img className="img-fluid mx-auto" src="assets/img/portfolio/thumbnails/MayaTrainer.jpg" alt="..." />
                </div>
            </div>
        </>
    )
};

export default Trainers;