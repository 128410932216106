import React, {useRef, useState} from 'react';
import emailjs from '@emailjs/browser';

const CampContactForm = () => {

    const form = useRef();
    const [step, setStep] = useState(1);


    // set whether contact box should appear

    const handleNextStep = (e) => {
        e.preventDefault();
        setStep(step + 1);
      };
    
      const handlePreviousStep = () => {
        setStep(step - 1);
      };


    //   emailJS to handle contact messages
    
    const sendEmail = (e) => {
      e.preventDefault();

      handleNextStep(e);
  
      emailjs.sendForm('service_ce0awku', 'template_84rqlnk', form.current, 'OFJvPwAvnle_35NG2')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };

    return(
        <section className="page-section" id="contact">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                <div className="col-lg-8 col-xl-6 text-center">
                    <h2 className="mt-0">Register Below</h2>
                    <hr className="divider" />
                    <p className="text-muted mb-5">If you're interested in attending a camp, please complete the form below to register, thank you!</p>
                </div>
                </div>
                <div className="row gx-4 gx-lg-5 justify-content-center mb-5">
                <div className="col-lg-6"> 
                {step === 1 && (
                    <form id="contactForm" ref={form} onSubmit={sendEmail} >
                    {/* Name input*/}
                    <div className="form-floating mb-3">
                        <input className="form-control" id="name" type="text" placeholder="Enter your name..." name="user_name" required />
                        <label htmlFor="name">Full name</label>
                        <div className="invalid-feedback" >A name is required.</div>
                    </div>
                    {/* Child's Name */}
                    <div className="form-floating mb-3">
                        <input className="form-control" id="name" type="text" placeholder="Enter your child's name..." name="child_name" required />
                        <label htmlFor="child's name">Child's full name</label>
                        <div className="invalid-feedback" >A child's name is required.</div>
                    </div>
                    {/* Email address input*/}
                    <div className="form-floating mb-3">
                        <input className="form-control" id="email" type="email" placeholder="name@example.com" name="user_email" required />
                        <label htmlFor="email">Email address</label>

                    </div>
                    {/* Phone number input*/}
                    <div className="form-floating mb-3">
                        <input className="form-control" id="phone" type="tel" placeholder="(123) 456-7890" name="phone_number" minLength={9} required/>
                        <label htmlFor="phone">Phone number</label>
                    </div>
                    {/* Experience Level */}
                    <div className="form-floating mb-3">
                        <input className="form-control" id="name" type="text" placeholder="Beginner, Intermediate, etc." name="experience" required />
                        <label htmlFor="experience level">Experience Level</label>
                        <div className="invalid-feedback" >Experiece level is required.</div>
                    </div>
                    {/* Submit Button*/}
                    <div className="d-grid"><button className="btn btn-primary btn-xl" id="submitButton" type="submit">Register</button></div>
                    </form>
                )}
                {step === 2 && (
                     <section className="page-section bg-primary" id="about">
                        <div className="container px-4 px-lg-5">
                            <div className="row gx-4 gx-lg-5 justify-content-center">
                                <div className="col-lg-8 text-center">
                                    <h2 className="text-white mt-0">You're registered for the camp!</h2>
                                    <hr className="divider divider-light" />
                                    <button className="btn btn-light btn-xl" type="button" onClick={handlePreviousStep}>Register another child</button>
                                </div>
                            </div>
                        </div>
                    </section>
                )}
                </div>
                {/* Contact info */}
                </div>
                    <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-lg-4 text-center mb-5 mb-lg-0">
                    <i className="bi-phone fs-2 mb-3 text-muted" />
                    <div>404-992-8335</div>
                </div>
                </div>
            </div>
        </section>

    )
};

export default CampContactForm;