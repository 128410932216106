import React from 'react';
import { NavLink } from 'react-router-dom';

const Nav = () => {

    const handleCollapse = () => {
        console.log("handleCollapse");
        var nav = document.getElementById("navbarResponsive");
        var btn = document.getElementById("navbarBtn");
        nav.classList.remove("show");
        btn.classList.add("collapsed");
      };

    return(
       <nav className="navbar navbar-expand-lg navbar-light fixed-top py-3" id="mainNav">
            <div className="container px-4 px-lg-5">
                <NavLink className="navbar-brand" to="Home">Fire Ring Farm</NavLink>
                <button className="navbar-toggler navbar-toggler-right" id="navbarBtn" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon" /></button>
                <div className="collapse navbar-collapse" id="navbarResponsive">
                    <ul className="navbar-nav ms-auto my-2 my-lg-0">
                        <li className="nav-item">
                            <NavLink className="nav-link" to="Home" onClick={handleCollapse}>Home</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="Calendar" onClick={handleCollapse}>Calendar</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="Camps" onClick={handleCollapse}>Camps</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="Trainers" onClick={handleCollapse}>Trainers</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="Facility" onClick={handleCollapse}>Facilty & Services</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="Contact" onClick={handleCollapse}>Contact</NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
};

export default Nav;