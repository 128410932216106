import React from 'react';

const CalendarPage = () =>{
    return(
        <>
            <header className="mastheadCal">
                    <div className="container px-4 px-lg-5 h-100">
                        <div className="row gx-4 gx-lg-5 h-100 align-items-center justify-content-center text-center">
                        <div className="col-lg-8 align-self-end">
                            <h1 className="text-white font-weight-bold">What We're Up To</h1>
                            <hr className="divider" />
                        </div>
                        <div className="col-lg-8 align-self-baseline">
                            <p className="text-white-75 mb-5">Camps,Horse Shows, Field Trips, and Gatherings</p>
                        </div>
                        </div>
                    </div>
            </header>
            <section className="page-section bg-primary" id="calendar">
                <div className="container px-4 px-lg-5">
                        <div className="row gx-4 gx-lg-5 justify-content-center">
                        <iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23616161&ctz=America%2FNew_York&showPrint=0&showTabs=0&showTitle=0&src=ZnJmYXJtY29ueWVyc0BnbWFpbC5jb20&src=ZW4udXNhI2hvbGlkYXlAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&color=%23039BE5&color=%230B8043" title="FRFarmCalendar" style={{border: 'none 1px #777'}} width={800} height={600} />
                        </div>
                    </div>
            </section>
        </>

    )
};

export default CalendarPage;