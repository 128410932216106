import React, {useRef, useState} from 'react';
import emailjs from '@emailjs/browser';

const Contact = () => {

    const form = useRef();
    const [step, setStep] = useState(1);


    // set whether contact box should appear

    const handleNextStep = (e) => {
        e.preventDefault();
        setStep(step + 1);
      };
    
      const handlePreviousStep = () => {
        setStep(step - 1);
      };


    //   emailJS to handle contact messages
    
    const sendEmail = (e) => {
      e.preventDefault();

      handleNextStep(e);
  
      emailjs.sendForm('service_ce0awku', 'template_l0x4wty', form.current, 'OFJvPwAvnle_35NG2')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };

    return(
        <section className="page-section" id="contact">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                <div className="col-lg-8 col-xl-6 text-center">
                    <h2 className="mt-0">Let's Get In Touch!</h2>
                    <hr className="divider" />
                    <p className="text-muted mb-5">Ready to start riding with us? Send us a messages and we will get back to you as soon as possible!</p>
                </div>
                </div>
                <div className="row gx-4 gx-lg-5 justify-content-center mb-5">
                <div className="col-lg-6"> 
                {step === 1 && (
                    <form id="contactForm" ref={form} onSubmit={sendEmail} >
                    {/* Name input*/}
                    <div className="form-floating mb-3">
                        <input className="form-control" id="name" type="text" placeholder="Enter your name..." name="user_name" required />
                        <label htmlFor="name">Full name</label>
                        <div className="invalid-feedback" >A name is required.</div>
                    </div>
                    {/* Email address input*/}
                    <div className="form-floating mb-3">
                        <input className="form-control" id="email" type="email" placeholder="name@example.com" name="user_email" required />
                        <label htmlFor="email">Email address</label>

                    </div>
                    {/* Phone number input*/}
                    <div className="form-floating mb-3">
                        <input className="form-control" id="phone" type="tel" placeholder="(123) 456-7890" name="phone_number" minLength={9} required/>
                        <label htmlFor="phone">Phone number</label>
                    </div>
                    {/* Message input*/}
                    <div className="form-floating mb-3">
                        <textarea className="form-control" id="message" type="text" placeholder="Enter your message here..." style={{height: '10rem'}}  defaultValue={""} name="message" required />
                        <label htmlFor="message">Message</label>
                    </div>
                    {/* Submit Button*/}
                    <div className="d-grid"><button className="btn btn-primary btn-xl" id="submitButton" type="submit">Submit</button></div>
                    </form>
                )}
                {step === 2 && (
                     <section className="page-section bg-primary" id="about">
                        <div className="container px-4 px-lg-5">
                            <div className="row gx-4 gx-lg-5 justify-content-center">
                                <div className="col-lg-8 text-center">
                                    <h2 className="text-white mt-0">Your message has been sent!</h2>
                                    <hr className="divider divider-light" />
                                    <button className="btn btn-light btn-xl" type="button" onClick={handlePreviousStep}>Send Another Message</button>
                                </div>
                            </div>
                        </div>
                    </section>
                )}
                </div>
                {/* Contact info */}
                </div>
                    <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-lg-4 text-center mb-5 mb-lg-0">
                    <i className="bi-phone fs-2 mb-3 text-muted" />
                    <div>404-992-8335</div>
                </div>
                </div>
            </div>
        </section>
    )
};

export default Contact;