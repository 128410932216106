import React from 'react';

const Services = () => {
    return(
        <section className="page-section" id="services">
            <div className="container px-4 px-lg-5">
                <h2 className="text-center mt-0"> Services</h2>
                <hr className="divider" />
                <div className="row gx-4 gx-lg-5">
                <div className="col-lg-3 col-md-6 text-center">
                    <div className="mt-5">
                    <div className="mb-2"><i className="bi-trophy fs-1 text-primary" /></div>
                    <h3 className="h4 mb-2">Lesson Program</h3>
                    <p className="text-muted mb-0">Horseback riding and horsemanship lessons offered for all ages and levels on our safe and well maintained horses and ponies.</p>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 text-center">
                    <div className="mt-5">
                    <div className="mb-2"><i className="bi-house fs-1 text-primary" /></div>
                    <h3 className="h4 mb-2">Boarding</h3>
                    <p className="text-muted mb-0">Fire Ring Farm is happy to offer full and pasture board. Barn owner and manager live onsite. </p>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 text-center">
                    <div className="mt-5">
                    <div className="mb-2"><i className="bi-basket fs-1 text-primary" /></div>
                    <h3 className="h4 mb-2">Camps</h3>
                    <p className="text-muted mb-0">Seasonal camps offered for ages 5 and up year round. All skill levels welcomed!</p>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 text-center">
                    <div className="mt-5">
                    <div className="mb-2"><i className="bi-stopwatch fs-1 text-primary" /></div>
                    <h3 className="h4 mb-2">Training Program</h3>
                    <p className="text-muted mb-0">Extensive training program that is tailored to you and your horses goals.</p>
                    </div>
                </div>
                </div>
            </div>
        </section>

    )
};

export default Services;
