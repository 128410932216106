import React from 'react';

const Masthead = () => {
    return(
        <header className="masthead">
            <div className="container px-4 px-lg-5 h-100">
                <div className="row gx-4 gx-lg-5 h-100 align-items-center justify-content-center text-center">
                <div className="col-lg-8 align-self-end">
                    <h1 className="text-white font-weight-bold">The Farm Where You and Your Horse Will Feel At Home</h1>
                    <hr className="divider" />
                </div>
                <div className="col-lg-8 align-self-baseline">
                    <p className="text-white-75 mb-5">Join us and enjoy the ride!</p>
                </div>
                </div>
            </div>
        </header>

    )
};

export default Masthead;