import React from 'react';

const Footer = () => {
    return(
        <footer className="bg-light py-5">
            <div className="container px-4 px-lg-5">
                <div className="small text-center text-muted">
                    <ul className="list-unstyled">
                    <ul className="list-unstyled">
                            <li>
                                <a className="bi-facebook fs-1 text-secondary text-decoration-none" href="https://www.facebook.com/p/Fire-Ring-Farm-100057402440193/"> </a>
                                <a className="bi-instagram fs-1 text-secondary" href="https://www.instagram.com/frfequestrian"> </a>
                            </li>
                        </ul>
                        <li>Fire Ring Farm LLC</li>
                        <li>2555 Ho Hum Hollow Road</li>
                        <li>Monroe, GA 30655</li>
                        <li>404-992-8335</li>
                    </ul>
                Copyright © 2023 - Fire Ring Farm
                </div>
            </div>
        </footer>
    )
};

export default Footer;